import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './New_lk.css';
import 'react-toastify/dist/ReactToastify.css';
import logo from "../Logo.svg";
import LetterByLetterText from "../components/LetterByLetterText";
import Wiki from "../Wiki/Wiki";
import Switch from "./components/Switch";

const tg = window.Telegram.WebApp;

function New_lk() {
    const [isLoading, setIsLoading] = useState(true);
    const [Premium, setPremium] = useState(false);
    const [payment, setPayment] = useState([]);
    const [PayDay, setPayDay] = useState([]);
    const [currentServer, setCurrentServer] = useState([]);
    const [currentProtocol, setCurrentProtocol] = useState([]);
    const [accessUrlSS, setAccessUrlSS] = useState([]);
    const [accessUrlVLESS, setAccessUrlVLESS] = useState([]);
    const [RefUrl, setRefUrl] = useState([]);

    const [showOnlyWiki, setShowOnlyWiki] = useState(false);

    tg.setHeaderColor('#1A659E');
    tg.expand();

    useEffect(() => {
        const preventTouchZoom = (e) => {
            if (e.touches.length > 1) {
                e.preventDefault();
            }
        };
        setTimeout(() => {
            setIsLoading(false);
        }, 2000);
        window.addEventListener('touchstart', preventTouchZoom, { passive: false });
        return () => {
            window.removeEventListener('touchstart', preventTouchZoom);
        };
    }, []);

    useEffect(() => {
        const preventZoom = (e) => {
            if (e.ctrlKey || e.metaKey) {
                e.preventDefault();
            }
        };

        window.addEventListener('wheel', preventZoom, { passive: false });
        return () => {
            window.removeEventListener('wheel', preventZoom);
        };
    }, []);

    useEffect(() => {
        const hapticFeedback = async () => {
            let haptics = Premium ? 12 : 9;

            await new Promise(resolve => setTimeout(resolve, 80));
            tg.HapticFeedback.impactOccurred('heavy');
            await new Promise(resolve => setTimeout(resolve, 2000));
            for (let i = 0; i < haptics; i++) {
                tg.HapticFeedback.impactOccurred('rigid');
                await new Promise(resolve => setTimeout(resolve, 40));
            }
        };
        hapticFeedback();
    }, [Premium]);

    useEffect(() => {
        const handleScroll = () => {
            const cards = document.querySelectorAll('.new-subscription-card');
            const scrollPos = window.scrollY;
            const minMargin = 1;
            const maxMargin = 15;

            cards.forEach((card) => {
                const newMargin = maxMargin - (scrollPos * 0.1);
                card.style.marginBottom = `${Math.max(newMargin, minMargin)}px`;
            });
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const Prolong = () => {
        tg.HapticFeedback.notificationOccurred('success');

        if (Premium === true) {
            window.open(`https://click-vpn.ru/new_payment_premium/${tg.initDataUnsafe?.user?.id}`, "_blank", "noopener noreferrer");
        } else {
            window.open(`https://click-vpn.ru/new_payment/${tg.initDataUnsafe?.user?.id}`, "_blank", "noopener noreferrer");
        }
    };

    const handleGift = () => {
        tg.HapticFeedback.notificationOccurred('success');
        window.open(`https://click-vpn.ru/new_payment_gift/${tg.initDataUnsafe?.user?.id}`, "_blank", "noopener noreferrer");
    };

    useEffect(() => {
        axios.get(`https://click-vpn.ru:8443/API/userAccount/${tg.initDataUnsafe?.user?.id}`)
            .then(response => {

                if (response.data.date === "Invalid date") {
                    setShowOnlyWiki(true);
                } else {
                    setPayDay(response.data.date);
                    setPremium(response.data.premium);
                    setPayment(response.data.payment);
                    setCurrentServer(response.data.currentServer);
                    setCurrentProtocol(response.data.currentProtocol);

                    setAccessUrlSS(response.data.accessKey);
                    setAccessUrlVLESS(response.data.subscribe_link);

                    setRefUrl(`https://t.me/First_VPN_Click_bot?start=${response.data.id}`)
                    setShowOnlyWiki(false);
                }

                setTimeout(() => {
                    setIsLoading(false);
                }, 2000);
            })
            .catch(error => {
                setShowOnlyWiki(true);
                console.error(error);
            });
    }, []);

    function copyToClipboard(text) {
        const updatedText = text.replace(/%23/g, '#');
        const textField = document.createElement('textarea');
        textField.innerText = updatedText;
        document.body.appendChild(textField);
        textField.select();
        document.execCommand('copy');
        textField.remove();
    }

    const handleOpenOutline = () => {
        tg.showConfirm(
            "Ключ скопирован 🔑\n\nОткрыть Outline?",
            function (confirmation) {
                if (confirmation) {
                    window.open(`https://click-vpn.ru/ConnectOutline?url=${accessUrlSS}`, "_blank", "noopener noreferrer");
                }
            }
        );
    };

    const handleOpenVLESS = () => {
        tg.showConfirm(
            "Ключ скопирован 🔑\n\nОткрыть приложение для VLESS?",
            function (confirmation) {
                if (confirmation) {
                    window.open(`https://click-vpn.ru/ConnectVLESS?url=${accessUrlVLESS}`, "_blank", "noopener noreferrer");
                }
            }
        );
    };

    if ({PayDay} === 'Invalid date') {
        return <Wiki />; // Отображаем только Wiki, если есть ошибка
    }

    if (showOnlyWiki) {
        return <Wiki />;
    }

    return (
        <div className="lk">
            <div className="new-loading-container">
                <img src={logo} className={`loading-logo ${isLoading ? 'slide-in' : 'move-right'}`} alt="Загрузка..." />
                {!isLoading && (
                    <div className="click-vpn">
                        {Premium ? (
                            <div className="new-li-text">
                                <LetterByLetterText text="Click PREMIUM" delay={40} />
                            </div>
                        ) : <LetterByLetterText text="Click VPN" delay={40} />}
                    </div>
                )}
            </div>

            {isLoading ? (
                <div></div>
            ) : (
                <>
                    {/* Карточка с информацией о подписке */}
                    <div className="new-subscription-card">
                        <div className="new-subscription-title">Подписка</div>
                        <p className="subscription-details">
                            {payment && <>Оплачено до </>}
                            {PayDay}
                        </p>
                        <div className="subscription-actions">
                            <button className="new-subscription-button" onClick={Prolong}>
                                Продлить 📅
                            </button>
                            <button className="new-subscription-button-1" onClick={handleGift}>
                                Подарить 🎁
                            </button>
                        </div>
                    </div>

                    {payment ? (
                        <>
                            {/* Карточка с информацией о локации */}
                            <div className="new-subscription-card">
                                <div className="new-subscription-title">
                                    Outline
                                    <div className="help-icon" onClick={() => {
                                        tg.showAlert("Outline работает на базе протокола ShadowSocks 🌐\n\nОн не такой быстрый, как VLESS, но зато имеет простое приложение.\n\nЧтобы сменить локацию Outline - нажми 'Сменить'.");
                                        tg.HapticFeedback.impactOccurred('rigid');
                                    }}>
                                        ?
                                    </div>
                                </div>
                                <p className="subscription-details">
                                    Локация: {(currentServer && currentServer !== "DLMS") ? currentServer : "Самая быстрая"}
                                    <i className="location" onClick={() => {
                                        tg.HapticFeedback.notificationOccurred('success');
                                        window.location.href = '/location';
                                    }}>
                                        Сменить &gt;
                                    </i>
                                </p>
                                <div className="subscription-actions-copy">
                                    <button className="new-subscription-button-copy" onClick={() => {
                                        tg.HapticFeedback.notificationOccurred('success');
                                        copyToClipboard(accessUrlSS);
                                        handleOpenOutline();
                                    }}>
                                        Скопировать ключ доступа 📋
                                    </button>
                                </div>
                            </div>

                            {/* Карточка с информацией о VLESS */}
                            <div className="new-subscription-card">
                                <div className="new-subscription-title">
                                    VLESS
                                    <div className="help-icon" onClick={() => {
                                        tg.showAlert("VLESS - более современный и быстрый VPN протокол 🚀\n\nЧтобы его подключить - нажми на переключатель справа.\n\nЧтобы узнать самый быстрый сервер (на текущий момент) - нажми 'Подобрать локацию'.");
                                        tg.HapticFeedback.impactOccurred('rigid');
                                    }}>
                                        ?
                                    </div>
                                    <Switch currentServer={currentProtocol}/>
                                </div>
                                <p className="subscription-details-location" onClick={() => {
                                    tg.HapticFeedback.notificationOccurred('success');
                                    window.location.href = '/test_location';
                                }}>Подобрать локацию ></p>
                                <div className="subscription-actions-copy">
                                    <button
                                        className="new-subscription-button-copy"
                                        onClick={() => {
                                            if (!accessUrlVLESS) {
                                                tg.HapticFeedback.notificationOccurred('error');
                                                tg.showAlert("Для использования VLESS необходимо сначала включить этот протокол.");
                                                return;
                                            }
                                            tg.HapticFeedback.notificationOccurred('success');
                                            copyToClipboard(accessUrlVLESS);
                                            handleOpenVLESS();
                                        }}
                                    >
                                        Скопировать ключ доступа 📋
                                    </button>
                                </div>
                            </div>

                            {/* Карточка с реферальной ссылкой */}
                            <div className="new-subscription-card">
                                <div className="new-subscription-title">
                                    Реферальная ссылка
                                </div>
                                <p className="subscription-details">{RefUrl}</p>

                                <div className="subscription-actions">
                                    <button className="new-subscription-button-2" onClick={() => {
                                        copyToClipboard(RefUrl)
                                        tg.HapticFeedback.notificationOccurred('success');
                                        tg.showAlert("Реферальная ссылка скопирована 🫂");
                                    }}>
                                        Скопировать 📋
                                    </button>

                                    <button className="new-subscription-button-2" onClick={() => {
                                        tg.HapticFeedback.notificationOccurred('success');
                                        tg.shareToStory('https://click-vpn.ru/phone.jpg', {
                                            text: 'Делюсь надёжным VPN! По ссылке месяц в подарок.',
                                            widget_link: {
                                                url: RefUrl,
                                            }
                                        });


                                    }}>
                                        Поделиться 📲
                                    </button>
                                </div>
                            </div>

                            {/* Карточка настроек */}
                            <div className="new-subscription-card-settings" onClick={() => {
                                tg.HapticFeedback.notificationOccurred('success');
                                window.location.href = '/settings';
                            }}>
                                <div className="new-subscription-title">Настройки</div>
                                <div className="new-arrow">></div>
                            </div>
                        </>
                    ) : (
                        <Wiki />
                    )}
                </>
            )}
        </div>
    );
}

export default New_lk;
