import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router';
import './Wiki.css';
import {useTelegram} from "../hooks/useTelegram"

function Wiki() {
    const {tg} = useTelegram();
    const [visibleIndex, setVisibleIndex] = useState(-1);
    const location = useLocation();

    const sections = [
        {
            title: "Преимущества Click VPN ✨",
            content: [
                { label: "💰 Это выгодно", text: "Стоимость от 99 рублей в месяц. Лучше предложения не найдёшь!" },
                { label: "🔐 Это безопасно", text: "Надёжное подключение через Shadowsocks или VLESS." },
                { label: "🤖 Это удобно", text: "Простой телеграм бот для управления. Всегда один ключ доступа." },
                { label: "✨ DLMS", text: "Dynamic Load Monitoring System. Каждый раз, подключаясь к Click VPN, ты получаешь сервер с наименьшей нагрузкой." },
                { label: "🤝 Реферальная программа", text: "1 месяц тебе и другу в подарок!" }
            ]
        },
        {
            title: "Как работает Click VPN? 🤔",
            content: [
                "🌐Мы арендуем серверы в европейских странах.",
                "🔒Настраиваем каждый сервер для безопасного подключения.",
                "🔑После оплаты ты получаешь ключ доступа к серверу и инструкцию в телеграм боте.",
                "⚙️Устанавливаешь приложение Outline или Hiddify/V2Ray.",
                "📲Подключаешься к серверу.",
                "✅Готово! Трафик шифруется, а Ты можешь пользоваться любимыми сервисами и приложениями!"
            ]
        },
        {
            title: "Какие локации доступны? 🌍",
            content: [
                { label: "🇫🇮 Финляндия" },
                { label: "🇬🇧 Великобритания" },
                { label: "🇩🇪 Германия" },
                { label: "🇺🇸 США" },
                { label: "🇦🇹 Австрия" },
                { label: "🇳🇱 Нидерланды" },
                { label: "🇱🇹 Литва" },
                { label: "🇱🇻 Латвия" },
                { label: "🇫🇷 Франция" },
                { label: "🇹🇷 Турция" },
                { label: "🇷🇺 Россия" },
                { label: "🇵🇱 Польша" },
                { label: "🇲🇰 Македония" },
                { label: "🇸🇪 Швеция" },
                { label: "🇨🇭 Швейцария" },
                { label: "🇷🇴 Румыния" },
                { label: "🇪🇪 Эстония" },
                { label: "🇩🇰 Дания" },
            ]
        },
        {
            title: "У меня не работает VPN. Что делать? 😢",
            content: [
                "Первое, что стоит сделать - это переустановить приложение (удалить его, а потом снова скачать) и перезагрузить телефон. \n",
                "\n",
                "Обычно помогает при таких проблемах.\n",
                "\n",
                "Если не помогло - попробуйте сменить локацию через личный кабинет, если пользуетеся Outline. Или через само приложение, если пользуетесь VLESS.\n",
                "\n",
                "Если вдруг и это не помогло, напишите нам в поддержку!",
            ]
        },
        {
            title: "У меня медленно работает VPN 🧊",
            content: [
                "Если вы пользуетесь Outline, то смените протокол подключения. VLESS значительно быстрее и производительнее, чем Outline.\n" +
                "\n",
                "Сменить протокол можно через личный кабинет. Кнопка \"подключить VLESS\".",
            ]
        },
        {
            title: "А платить безопасно? 😨",
            content: [
                "Платеж обарабатывается на стороне платежного сервиса ЮКасса, это значит, что бот не имеет доступа к банковским данным.\n",
                "А сумма списывается только та, которая указана в самом платеже.\n",
                "Поэтому платеж абсолютно безопасен.\n",
                "\n",
                "Даже если у тебя что-то не будет работать, ты можешь обратиться в поддержку.",
            ]
        },
        {
            title: "Ваш VPN безопасен? 🔒",
            content: [
                "Наш VPN работает на базе приложений Outline или V2RAY. Эти приложения не собирают личные данные. Сервис также не собирает сведения о посещенных сайтах или о взаимодействии с другими пользователями или программами. \n",
                "Весь трафик шифруется.",
            ]
        },
        {
            title: "Нужно ли отключать VPN? ❌",
            content: [
                "Большинство отечественных сервисов (Банки, Госуслуги и пр.) не позволяют подключаться к ним, используя VPN.\n",
                "Для комфортного пользования интернет-ресурсами, рекомендуем включать VPN только по необходимости.\n",
            ]
        },
        {
            title: "Можно ли пользоваться торрентом через VPN? 🏴‍☠️",
            content: [
                "Дело в том, что европейские провайдеры могут заблокировать сервер, через который проходит торрент-трафик. Для избежания блокировок рекомендуется отключать VPN при загрузке через торрент.\n",
                "\n",
                "Также загрузка файлов через торрент может повлиять на качество соединения других пользователей. И привести к полной блокировке сервера, из-за чего VPN перестанет работать у тебя и у других пользователей.",
            ]
        },
        {
            title: "Как отключить подписку? 💸",
            content: [
                "Чтобы отключить подписку:\n",
                "1. Нажми \"Личный кабинет\".\n",
                "2. Нажми \"Настройки\"\n",
                "3. Нажми \"Отключить подписку\".\n",
                "Готово! ✅",
            ]
        }
    ];

    const handleClick = (index) => {
        setVisibleIndex(visibleIndex === index ? -1 : index);
    };

    tg.expand();

    // Функция для извлечения тега из URL и установки соответствующего видимого индекса
    const setDefaultVisibleIndex = () => {
        const urlParams = new URLSearchParams(location.search);
        const tag = urlParams.get('tag');
        if (tag) {
            const index = sections.findIndex(section => section.title.includes(tag));
            if (index !== -1) {
                setVisibleIndex(index);
            }
        }
    };

    // Вызываем setDefaultVisibleIndex при монтировании компонента или изменении местоположения
    useEffect(() => {
        setDefaultVisibleIndex();
    }, [location.search]);

    return (
        <div className="Wiki">
            {sections.map((section, index) => (
                <React.Fragment key={index}>
                    <button className="WikiButton" onClick={() => {
                        handleClick(index)
                        tg.HapticFeedback.impactOccurred('rigid')
                    }}>
                        <h3>{section.title}</h3>
                    </button>
                    <section className={`WikiCard ${visibleIndex === index ? 'visible' : ''}`}>
                        {section.content.map((item, i) => (
                            typeof item === "string" ? <p key={i}>{item}</p> : (
                                <React.Fragment key={i}>
                                    <b>{item.label}</b>
                                    <p className="Wiki-p">{item.text}</p>
                                </React.Fragment>
                            )
                        ))}
                    </section>
                </React.Fragment>
            ))}
        </div>
    );
}

export default Wiki;