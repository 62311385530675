import React, { useEffect, useState } from 'react';
import '../New_lk.css';
import './location.css';
import axios from 'axios';

const tg = window.Telegram.WebApp;

function Location() {
    const [selectedIndex, setSelectedIndex] = useState(null);

    useEffect(() => {
        const preventTouchZoom = (e) => {
            if (e.touches.length > 1) {
                e.preventDefault();
            }
        };
        window.addEventListener('touchstart', preventTouchZoom, { passive: false });
        return () => {
            window.removeEventListener('touchstart', preventTouchZoom);
        };
    }, []);

    useEffect(() => {
        const preventZoom = (e) => {
            if (e.ctrlKey || e.metaKey) {
                e.preventDefault();
            }
        };

        window.addEventListener('wheel', preventZoom, { passive: false });
        return () => {
            window.removeEventListener('wheel', preventZoom);
        };
    }, []);

    const locations = [
        { name: 'DLMS', value: 'DLMS', emoji: '⚡️', description: 'DLMS - Dynamic Load Monitoring System⚡️\n\nСистема мониторинга нагрузки серверов.\n\nОна подключает тебя к самой быстрой и свободной локации.' },
        { name: 'Финляндия', value: 'Финляндия', emoji: '🇫🇮' },
        { name: 'Германия', value: 'Германия', emoji: '🇩🇪' },
        { name: 'США', value: 'США', emoji: '🇺🇸' },
        { name: 'Австрия', value: 'Австрия', emoji: '🇦🇹' },
        { name: 'Нидерланды', value: 'Нидерланды', emoji: '🇳🇱' },
        { name: 'Литва', value: 'Литва', emoji: '🇱🇹' },
        { name: 'Латвия', value: 'Латвия', emoji: '🇱🇻' },
        { name: 'Франция', value: 'Франция', emoji: '🇫🇷' },
        { name: 'Россия', value: 'Россия', emoji: '🇷🇺' },
        { name: 'Турция', value: 'Турция', emoji: '🇹🇷' },
        { name: 'Великобритания', value: 'Великобритания', emoji: '🇬🇧' },
        { name: 'Норвегия', value: 'Норвегия', emoji: '🇳🇴' },
        { name: 'Польша', value: 'Польша', emoji: '🇵🇱' },
        { name: 'Македония', value: 'Македония', emoji: '🇲🇰' },
        { name: 'Швеция', value: 'Швеция', emoji: '🇸🇪' },
        { name: 'Швейцария', value: 'Швейцария', emoji: '🇨🇭' },
        { name: 'Румыния', value: 'Румыния', emoji: '🇷🇴' },
        { name: 'Эстония', value: 'Эстония', emoji: '🇪🇪' },
        { name: 'Дания', value: 'Дания', emoji: '🇩🇰' },
    ];

    useEffect(() => {
        tg.setHeaderColor('#1A659E');
        tg.MainButton.color = "#1A659E";
        tg.MainButton.setParams({
            text: 'Выбрать локацию'
        });

        const handleMainButtonClick = () => {
            if (selectedIndex !== null) {
                const selectedLocation = locations[selectedIndex].value;

                const data = {
                    passwd: '3569',
                    userId: tg.initDataUnsafe?.user?.id,
                    currentServer: selectedLocation,
                };

                const url = 'https://click-vpn.ru:8443/API/currentServer';

                axios.post(url, data, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then((response) => {
                        tg.HapticFeedback.notificationOccurred('success');
                        console.log('Response:', response.data);
                        tg.close();
                    })
                    .catch((error) => {
                        console.error('Error:', error);
                    });
            }
        };

        tg.MainButton.onClick(handleMainButtonClick);

        return () => {
            tg.MainButton.offClick(handleMainButtonClick);
        };
    }, [selectedIndex]);

    const BackButton = tg.BackButton;
    BackButton.show();
    BackButton.onClick(function() {
        BackButton.hide();
        tg.HapticFeedback.impactOccurred('rigid');
    });
    tg.onEvent('backButtonClicked', function() {
        window.location.href = '/new_lk';
    });

    const handleCardClick = (index) => {
        if (selectedIndex !== index) {
            setSelectedIndex(index);
            tg.HapticFeedback.impactOccurred('soft');
            tg.MainButton.show();
        }
    };

    const handleHelpClick = (description) => {
        tg.HapticFeedback.impactOccurred('rigid');
        tg.showAlert(description);
    };

    return (
        <div className="lk">
            <div className="new-subscription-card">
                <div className="new-subscription-title">Выбери локацию для Outline:</div>
            </div>

            {locations.map((location, index) => (
                <div
                    className={`new-subscription-card-location ${selectedIndex === index ? 'selected' : ''}`}
                    key={index}
                    onClick={() => handleCardClick(index)}
                >
                    <div className="new-subscription-title">
                        {location.name} <div>{location.emoji}</div>
                        {location.value === 'DLMS' && (
                            <div
                                className="help-icon"
                                onClick={() => handleHelpClick(location.description)}
                            >
                                ?
                            </div>
                        )}
                    </div>
                </div>
            ))}
        </div>
    );
}

export default Location;
